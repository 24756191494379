import { PageProps } from 'gatsby';
import { Group } from 'packages/formidable';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import AppNotebook from '~/apps/Notebook';
import Header from '~/components/Header';
import CMSView from '~/components/View';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';
import useNotes from '~/utils/useNotes';

const Thoughts: FC<EspaceProps & PageProps & UserProps> = ({
  espace,
  location,
  user,
}) => {
  const { t } = useTranslation();
  const items = useNotes({
    espaceId: espace.id,
    pathname: location.pathname,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Header subMenu={items} title={t('notes.title')} />
        <Group className="mt-6">
          <AppNotebook espaceId={espace.id} limit={10} showButtonList={false} />
        </Group>

        {/* <List */}
        {/*  itemList={Item} */}
        {/*  itemPathnamePrefix={`/espaces/${espace.id}/notes/thoughts/`} */}
        {/*  model={ */}
        {/*    new ThoughtData({ */}
        {/*      espaceId: espace.id, */}
        {/*      params: thought, */}
        {/*    }) */}
        {/*  } */}
        {/*  pathname={location.pathname} */}
        {/*  search={location.search} */}
        {/*  title={t('thoughts.title')} */}
        {/*  user={user} */}
        {/* /> */}
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(Thoughts);
